var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.materialId && _vm.novelMaterial
    ? _c(
        "div",
        { staticClass: "container" },
        [
          _vm.layoutType === "resume-layout"
            ? _c(
                "div",
                { staticClass: "material-basic-items resume-layout" },
                [
                  _c("load-image", {
                    staticClass: "basic-image",
                    class: {
                      preset: _vm.isPreset(
                        _vm.novelMaterial.items[2].imagePath
                      ),
                    },
                    attrs: {
                      path: _vm.novelMaterial.items[2].imagePath,
                      placeholder: "/img/placeholders/no_image_default.png",
                    },
                  }),
                  _c("div", { staticClass: "basic-text" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.novelMaterial.items[0].value)),
                    ]),
                    _vm.novelMaterial.items[1].value
                      ? _c("span", { staticClass: "ruby" }, [
                          _vm._v(_vm._s(_vm.novelMaterial.items[1].value)),
                        ])
                      : _vm._e(),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm.layoutType === "enlargement-layout"
            ? _c(
                "div",
                { staticClass: "material-basic-items enlargement-layout" },
                [
                  _c(
                    "div",
                    { staticClass: "image-container" },
                    [
                      _c("load-image", {
                        staticClass: "background-image blur-img",
                        class: {
                          preset: _vm.isPreset(
                            _vm.novelMaterial.items[2].imagePath
                          ),
                        },
                        attrs: {
                          path: _vm.novelMaterial.items[2].imagePath,
                          placeholder: "/img/placeholders/no_image_default.png",
                        },
                      }),
                      _c("load-image", {
                        staticClass: "basic-image",
                        class: {
                          preset: _vm.isPreset(
                            _vm.novelMaterial.items[2].imagePath
                          ),
                        },
                        attrs: {
                          path: _vm.novelMaterial.items[2].imagePath,
                          placeholder: "/img/placeholders/no_image_default.png",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "basic-text" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.novelMaterial.items[0].value)),
                    ]),
                    _vm.novelMaterial.items[1].value
                      ? _c("span", { staticClass: "ruby" }, [
                          _vm._v(_vm._s(_vm.novelMaterial.items[1].value)),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
          _vm.layoutType === "dictionary-layout"
            ? _c(
                "div",
                { staticClass: "material-basic-items dictionary-layout" },
                [
                  _c("div", { staticClass: "basic-text" }, [
                    _c("span", { staticClass: "name" }, [
                      _vm._v(_vm._s(_vm.novelMaterial.items[0].value)),
                    ]),
                    _vm.novelMaterial.items[1].value
                      ? _c("span", { staticClass: "ruby" }, [
                          _vm._v(_vm._s(_vm.novelMaterial.items[1].value)),
                        ])
                      : _vm._e(),
                  ]),
                ]
              )
            : _vm._e(),
          _c("div", { staticClass: "divider" }),
          _c(
            "div",
            { staticClass: "material-items" },
            _vm._l(_vm.items, function (item) {
              return _c("div", { key: item.id }, [
                item.type === "multiline-text"
                  ? _c("div", { staticClass: "material-item" }, [
                      item.title
                        ? _c("span", {
                            staticClass: "title",
                            domProps: { innerHTML: _vm._s(item.title) },
                          })
                        : _vm._e(),
                      _c("div", { staticClass: "space" }),
                      item.value
                        ? _c("span", {
                            staticClass: "detail",
                            domProps: { innerHTML: _vm._s(item.value) },
                          })
                        : _vm._e(),
                    ])
                  : _vm._e(),
                item.type === "image-square"
                  ? _c(
                      "div",
                      { staticClass: "material-item" },
                      [
                        item.title
                          ? _c("span", {
                              staticClass: "title",
                              domProps: { innerHTML: _vm._s(item.title) },
                            })
                          : _vm._e(),
                        _c("load-image", {
                          staticClass: "img-square",
                          class: { preset: _vm.isPreset(item.imagePath) },
                          attrs: {
                            path: item.imagePath,
                            placeholder:
                              "/img/placeholders/no_image_default.png",
                          },
                        }),
                        item.value
                          ? _c("span", {
                              staticClass: "detail",
                              domProps: { innerHTML: _vm._s(item.value) },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === "image-portrait"
                  ? _c(
                      "div",
                      { staticClass: "material-item" },
                      [
                        item.title
                          ? _c("span", {
                              staticClass: "title",
                              domProps: { innerHTML: _vm._s(item.title) },
                            })
                          : _vm._e(),
                        _c("load-image", {
                          staticClass: "img-portrate",
                          class: { preset: _vm.isPreset(item.imagePath) },
                          attrs: {
                            path: item.imagePath,
                            placeholder:
                              "/img/placeholders/no_image_default_small.png",
                          },
                        }),
                        item.value
                          ? _c("span", {
                              staticClass: "detail",
                              domProps: { innerHTML: _vm._s(item.value) },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
                item.type === "image-landscape"
                  ? _c(
                      "div",
                      { staticClass: "material-item" },
                      [
                        item.title
                          ? _c("span", {
                              staticClass: "title",
                              domProps: { innerHTML: _vm._s(item.title) },
                            })
                          : _vm._e(),
                        _c("load-image", {
                          staticClass: "img-landscape",
                          class: { preset: _vm.isPreset(item.imagePath) },
                          attrs: {
                            path: item.imagePath,
                            placeholder:
                              "/img/placeholders/no_image_default.png",
                          },
                        }),
                        item.value
                          ? _c("span", {
                              staticClass: "detail",
                              domProps: { innerHTML: _vm._s(item.value) },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  : _vm._e(),
              ])
            }),
            0
          ),
          !_vm.preview ? _c("div", { staticClass: "space" }) : _vm._e(),
          !_vm.preview
            ? _c(
                "button-round",
                {
                  attrs: {
                    to: {
                      name: "materialEdit",
                      params: { ..._vm.$route.params },
                    },
                  },
                },
                [_vm._v("編集する")]
              )
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }